import { all, takeLatest /* , put, call, delay, select*/ } from 'redux-saga/effects';
import fetchInvitation from './fetchInvitation';

//import signIn from './sign-in';
import signUp from './sign-up';
import signOut from './sign-out';
import confirmRegistration from './confirm-registration';
import confirmPassword from './confirm-password';
import resendConfirmationCode from './resend-confirmation-code';

import signInStart from './sign-in-start';
import signInComplete from './sign-in-complete';

import initialize from './initialize';

export default function* sagaRoot () {
  yield all([
    takeLatest('INITIALIZE', initialize),
    //takeLatest('SIGN_IN', signIn),
    takeLatest('SIGN_IN_START', signInStart),
    takeLatest('SIGN_IN_COMPLETE', signInComplete),

    takeLatest('SIGN_UP', signUp),
    takeLatest('SIGN_OUT', signOut),
    takeLatest('CONFIRM_REGISTRATION', confirmRegistration),
    takeLatest('CONFIRM_PASSWORD', confirmPassword),
    takeLatest('RESEND_CONFIRMATION_CODE', resendConfirmationCode),

    takeLatest('INVITATION_FETCH', fetchInvitation),
  ]);
}
