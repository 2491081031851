import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: url('/assets/auth-background-colorful.jpg');
  background-position: center center;
`

export const ContainerOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(239, 239, 234, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;  
  background-position: center center;
`

export const Box = styled.div`
  background: url('/assets/auth-background-colorful.jpg');
  background-position: center center;
  min-width: 430px;
  min-height: 350px;
  display: flex;
  overflow: hidden;
  border-radius: 18px;
  box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.15);
  font-family: 'Raleway', sans-serif;
  color: #666666;
`

export const FormContainer = styled.div`
  background-color: #ffffff;
  padding: 40px 40px;
  width: 380px;
  box-sizing: border-box;
`

export const LogoHolder = styled.div`
  display: flex;
  width: 380px;
  height: auto;
  align-items: center;
  justify-content: center;
`

export const ButtonPrimary = styled.button`
  background-color: #0084EE;
  color: #ffffff;
  padding: 7px 12px;
  border: 1px solid #0084EE;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: #ffffff;
    color: #0084EE;
  }
`

export const ButtonSecondary = styled.button`
  background-color: #ffffff;
  color: #0084EE;
  padding: 9px 17px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
`

export const FormTitle = styled.h2`
  margin-bottom: 20px;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  margin: 0;
  margin-bottom: 1em;
  font-size: 26px;
`

export const FormBody = styled.div`
`;

export const FormFooter = styled.div`
  width: 100%;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`