import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
//import bugsnag  from '@bugsnag/js';
import { put, call, select } from 'redux-saga/effects';
import storage from '../../cookie-storage';

function getJwt (username, password, cognitoUser) {
  return new Promise((resolve, reject) => {
    var authenticationParams = {
      Username: username,
      Password: password
    };

    var options = {
      onSuccess: (result) => {
        var accessToken = result.getAccessToken().getJwtToken();
        var idToken = result.getIdToken().getJwtToken();
        resolve({ accessToken, idToken });
      },
      onFailure: (error) => {
        console.log(error); // "NotAuthorizedException"
        reject(error);
      }
    };

    cognitoUser.authenticateUser(new AmazonCognitoIdentity.AuthenticationDetails(authenticationParams), options);
  });
}

function* signInComplete (action) {
  const { payload } = action;
  const state = yield select(state => state);

  const username = state.signInPage.data.username;
  const password = payload.password;

  console.log('Signing in...', action, state);

  yield put({ type: 'SIGN_IN.COMPLETE_REQUEST' });

  var responseParams = {
    type: 'SIGN_IN.COMPLETE_RESPONSE',
    payload: {
      touched: true,
      sending: false,
      error: null
    }
  };

  var userPoolParams = {
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    ClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    Storage: storage
  };

  var userPool = new AmazonCognitoIdentity.CognitoUserPool(userPoolParams);

  var cognitoUserParams = {
    Username: username,
    Pool: userPool,
    Storage: storage
  };

  var cognitoUser = new AmazonCognitoIdentity.CognitoUser(cognitoUserParams);

  try {
    var authResponse = yield call(getJwt, username, password, cognitoUser);
    responseParams.payload.jwt = authResponse.accessToken;
  }
  catch (e) {
    responseParams.payload.error = e.message || e.toString();
  }

  yield put(responseParams);
};

export default signInComplete;