const DEFAULT_STATE = {
  loading: false,
  errors: [],
  //isCheckingUser: false,
  //isSelectingUser: false,
  //isSigning: false,
  //signingErrors: [],
  start: {
    touched: false,
    sending: false,
    found: false,
    error: null,
  },
  complete: {
    touched: false,
    sending: false,
    error: null,
    jwt: null
  },
  data: {
    username: '', // 'william.wagner.br+1@gmail.com',

    // Status
    isRegistredUser: false,
    isEmailConfirmed: false,
    forcePasswordChange: false
  }
}

function signInReducer (state = DEFAULT_STATE, action) {
  if (action.type === 'SIGN_IN.USERNAME') {
    return {
      ...state,
      data: {
        ...state.data,
        username: action.payload.username
      }
    }
  }

  if (action.type === 'SIGN_IN.START_REQUEST') {
    return {
      ...state,
      start: {
        touched: true,
        sending: true,
        found: false,
        error: null
      }
    }
  }

  if (action.type === 'SIGN_IN.START_RESPONSE_OK') {
    return {
      ...state,
      start: {
        ...action.payload
      }
    }
  }

  if (action.type === 'SIGN_IN.START_RESPONSE_ERROR') {
    return {
      ...state,
      start: {
        sending: false,
        error: action.payload.error
      }
    }
  }

  if (action.type === 'SIGN_IN.COMPLETE_REQUEST') {
    return {
      ...state,
      complete: {
        touched: true,
        sending: true,
        error: null,
        jwt: null
      }
    }
  }

  if (action.type === 'SIGN_IN.COMPLETE_RESPONSE') {
    return {
      ...state,
      complete: {
        touched: true,
        sending: false,
        error: null,
        jwt: null,
        ...action.payload
      }
    }
  }

  return state;
}

export default signInReducer;