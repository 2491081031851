import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Box, Title } from '../../components/Whitebox';
import { Uncontrolled as User } from '../../components/User';
import Button from '../../components/Button';
import ButtonGroup from '../../components/ButtonGroup';
import ButtonContent from '../../components/ButtonContent';

import DashboardIcon from '@material-ui/icons/Computer'
import MapIcon from '@material-ui/icons/Map';

const HomePage = (props) => {

  /**
   * Handle open dashboard click
   * @param {Event} e 
   */
  const handleOpenDashboard = (e) => {
    window.location.href = process.env.REACT_APP_DASHBOARD_ENDPOINT;
  }

  /**
   * Handle open map click
   * @param {Event} e 
   */
  const handleOpenMap = (e) => {
    window.location.href = process.env.REACT_APP_MAP_ENDPOINT;
  }

  /**
   * Handle open sign-in page
   * @param {Event} e
   */
  const handleOpenSignIn = () => {
    props.history.push('/sign-in');
  }

  /**
   * Handle open sign-up page
   * @param {Event} e
   */
  /*
  const handleOpenSignUp = () => {
    props.history.push('/sign-up');
  }
  */

  const renderLoading = () => {
    return (<span>Carregando...</span>);
  }

  const renderSigned = () => {
    return (
      <>
        <span>Bem-vindo, {props.user.data.name}</span>
        <User />
        <br/>

        <ButtonGroup>
          <Button size="large" onClick={handleOpenDashboard}>
            <ButtonContent>
              <DashboardIcon />
              Abrir dashboard
            </ButtonContent>
          </Button>

          <Button size="large" onClick={handleOpenMap}>
            <ButtonContent>
              <MapIcon />
              Abrir mapa
            </ButtonContent>
          </Button>
        </ButtonGroup>
      </>
    )
  }

  const renderNonSigned = () => {
    return (
      <>
        <span>Faça login para ter acesso aos seus projetos</span>
        <br/>
        <br/>

        <ButtonGroup>
          <Button onClick={handleOpenSignIn}>
            Login
          </Button>

          {/*
          <Button onClick={handleOpenSignUp}>
            Cadastro
          </Button>
          */}
        </ButtonGroup>
      </>
    )
  }

  const getContent = () => {
    if (props.user.loading) {
      return renderLoading();
    }

    if (!props.user.data) {
      return renderNonSigned();
    }

    if (!props.user.connected) {
      return renderNonSigned();
    }

    return renderSigned();
  }

  return (
    <Box>
      <Title>Industrial Photo Management</Title>
      { getContent() }
    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
});

const withRedux = connect(mapStateToProps);
export default withRouter(withRedux(HomePage));