import { CookieStorage } from '@aws-amplify/auth';

export default new CookieStorage({
  domain: process.env.REACT_APP_DOMAIN,
  path: '/',
  expires: 30,
  secure: process.env.NODE_ENV === 'production'
});

//var storage = new AmazonCognitoIdentity.CookieStorage({ domain: process.env.REACT_APP_COOKIE_DOMAIN, secure: false });

/*
const memoryStorage = {};

const storage = {
  setItem: function (key, value) {
    memoryStorage[key] = value;
    return value;
  },
  getItem: function (key) {
    return memoryStorage[key];
  },
	removeItem(key) {
    delete(memoryStorage[key]);
    return memoryStorage;
  },
  clear: function () {
    Object.keys(memoryStorage).forEach((key) => {
      delete(memoryStorage[key]);
    });

    return {};
  }
};

export default storage;
*/