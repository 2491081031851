import React from 'react';
import PropTypes from 'prop-types';

const getColorClassName = (color) => {
  if (color) {
    return color;
  }

  return 'primary';
}

const Button = ({ element, size, color, variant,  disabled = false, children, ...props }) => {
  var elementProps = { ...props };
  var classes = ['btn', `btn-${size}`];

  if (element === 'button') {
    elementProps.type = 'button';
  }

  if (disabled) {
    classes.push('disabled');
  }

  if (variant === 'link') {
    classes.push('btn-link');
  }

  if (variant === 'outlined') {
    classes.push(`btn-outline-${getColorClassName(color)}`);
  }

  if (variant === 'contained') {
    classes.push(`btn-${getColorClassName(color)}`);
  }  
  
  if (!elementProps.className) {
    elementProps.className = '';
  }

  elementProps.className += classes.join(' ');

  return React.createElement(element, elementProps, children);
}

Button.propTypes = {
  disabled: PropTypes.bool,
  size: PropTypes.string,
  color: PropTypes.string
};

Button.defaultProps = {
  element: 'button',
  variant: 'contained',
  color: 'primary',
  size: 'normal'
}

export default Button;