//import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import bugsnag  from '@bugsnag/js';
//import storage from '../../cookie-storage';
import { put, call } from 'redux-saga/effects';

function checkUserExists (username) {
  return new Promise((resolve) => {
    Auth.signIn(username, '123')
      .catch((e) => {
        resolve(e.code !== "UserNotFoundException");
      });
  });
}

function* signInAccount (action, state) {
  try {
    const { payload } = action;
    console.log('Signing in account...', payload);

    var dispatchAction = {
      type: 'SIGN_IN.START_RESPONSE_OK',
      payload: {
        touched: true,
        sending: false,
        found: false,
        error: null
      }
    };

    yield put({
      type: 'SIGN_IN.START_REQUEST'
    });

    dispatchAction.payload.found = yield call(checkUserExists, payload.username);

    if (!dispatchAction.payload.found) {
      dispatchAction.payload.error = 'user_not_found';
    }

    yield put(dispatchAction);

    if (dispatchAction.payload.found) {
      yield put({
        type: 'SIGN_IN.USERNAME',
        payload: {
          username: payload.username
        }
      });
    }

    /*
    var userPoolParams = {
      UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      ClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
      Storage: storage
    };

    var userPool = new AmazonCognitoIdentity.CognitoUserPool(userPoolParams);

    var cognitoUserParams = {
      Username: payload.username,
      Pool: userPool,
      Storage: storage
    };

    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(cognitoUserParams);

    console.log('cognitoUser', cognitoUser);

    var authenticationParams = {
      Username: payload.username,
      Password: payload.password
    };

    var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationParams);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        //const userId = result.idToken.payload.sub;
        //const appId = result.idToken.payload.aud;
        var accessToken = result.getAccessToken().getJwtToken();
        var idToken = result.getIdToken().getJwtToken();
        console.log('success', { accessToken, idToken });
      },
      onFailure: (error) => {
        // Code here
        console.log('error', error);
      }
    });
    */

  } catch (e) {
    bugsnag.notify(e);
  }
};

export default signInAccount;