import Amplify from 'aws-amplify';

var amplifyOptions = {
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    cookieStorage: {
      domain: process.env.REACT_APP_DOMAIN,
      path: '/',
      expires: 30,
      secure: process.env.NODE_ENV === 'production'
    }
  }
};

Amplify.configure(amplifyOptions);

if (process.env.NODE_ENV === 'development') {
  window.Amplify = Amplify;
}

export default Amplify;