import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// Components
import { Title, Footer } from '../Whitebox';
import TextField from '../TextField';
import EmailField from '../EmailField';
import Button from '../Button';
import RedirectUri from '../RedirectUri';

class SignInForm extends React.Component {

  constructor (props) {
    super(props);

    this.state = {
      data: {
        username: '',
        password: ''
      }
    }
  }

  /**
   * Handle sign-in click
   * @param {Event} e
   */
  handleSubmit = (e) => {
    if (!this.props.signInPage.data.username) {
      return this.props.dispatch({
        type: 'SIGN_IN_START',
        payload: {
          username: this.state.data.username
        }
      });
    }

    this.props.dispatch({
      type: 'SIGN_IN_COMPLETE',
      payload: {
        password: this.state.data.password
      }
    });
  }

  /**
   * Handle change user click
   * @param {Event} e 
   */
  handleChangeUser = (e) => {
    this.props.dispatch({
      type: 'SIGN_IN.USERNAME',
      payload: {
        username: ''
      }
    });
  }

  /**
   * On field change
   * @param {String} field
   * @returns {Function} handler
   */
  handleFieldChange = (field) => {
    return (e) => {
      var newData = { ...this.state.data };
      newData[field] = e.target.value;
      this.setState({ data: newData });
    }
  }

  /**
   * On field blur
   * @param {String} field
   * @returns {Function} handler
   */
  handleFieldBlur = (field) => {
    return (e) => {
    }
  }

  /**
   * Get validation error for field
   * @param {String} field
   * @returns {String|undefined}
   */
  getValidationErrorOf = (field) => {
    return undefined;
  }

  render () {
    if (this.state.challenge === 'confirm_account') {
      return this.renderVerifyAccount();
    }

    return this.renderForm();
  }

  renderInputs () {
    if (!this.props.signInPage.data.username || this.props.signInPage.data.username.length === 0) {
      return (
        <EmailField
          label="Email"
          type="email"
          value={this.state.data.username}
          onChange={this.handleFieldChange('username')}
          error={this.getValidationErrorOf('username')}
          onBlur={this.handleFieldBlur('username')}
        />
      )
    }

    return (
      <React.Fragment>
        <div style={{margin: '4px'}}>
          <span>{this.props.signInPage.data.username.substring(0, 25)}...</span>
          <Button variant="link" color="primary" size="sm" onClick={this.handleChangeUser}>Alterar</Button>
        </div>

        <TextField
          label="Senha"
          type="password"
          value={this.state.data.password}
          onChange={this.handleFieldChange('password')}
          error={this.getValidationErrorOf('password')}
          onBlur={this.handleFieldBlur('password')}
        />
      </React.Fragment>
    )
  }

  renderStepOne = () => {
    return (
      <>
        <Title>Fazer login</Title>

        <div style={{margin: '-4px', marginBottom: '8px'}}>
          <EmailField
            label="Email"
            type="email"
            value={this.state.data.username}
            onChange={this.handleFieldChange('username')}
            error={this.getValidationErrorOf('username')}
            onBlur={this.handleFieldBlur('username')}
          />
        </div>

        {/*<Button variant="link" size="sm" style={{padding: '0px', marginTop: '-32px'}}>Esqueceu seu e-mail?</Button>*/}
        {/*<br/><span>[ ] Permanecer logado</span>*/}

        <Footer>
          {/*<Button color="primary" variant="outlined">Criar conta</Button>*/}
          <Button color="primary" onClick={this.handleSubmit}>
            { this.props.signInPage.start.sending ? 'Continuar...' : 'Próxima' }
          </Button>
        </Footer>

        { this.props.signInPage.start.touched && <small style={{color: 'red'}}>{this.props.signInPage.start.error}</small> }
      </>
    );
  }

  renderStepTwo = () => {
    if (this.props.signInPage.complete.jwt) {
      var search = new URLSearchParams(this.props.location.search);

      if (search.has('redirectUri')) {
        console.log(search.get('redirectUri'))
        return (
          <RedirectUri to={search.get('redirectUri')} />
        )
      }

      return (
        <>
          <Title>Parabéns</Title>
          <span style={{textOverflow: 'clip', width: '100%', wordBreak: 'break-all'}}>{this.props.signInPage.complete.jwt}</span>
        </>
      )
    }

    return (
      <>
        <Title>Fazer login</Title>

        <div>
          <div className="form-username-box">
            <span>{this.props.signInPage.data.username.substring(0, 25)}...</span>
            <Button variant="link" color="primary" size="sm" onClick={this.handleChangeUser}>Alterar</Button>
          </div>

          <div style={{marginLeft: '-4px', marginRight: '-4px'}}>
            <TextField
              label="Senha"
              type="password"
              value={this.state.data.password}
              onChange={this.handleFieldChange('password')}
              error={this.getValidationErrorOf('password')}
              onBlur={this.handleFieldBlur('password')}
            />
          </div>

          {/*<Button variant="link" size="sm" style={{padding: '0px', marginTop: '-32px'}}>Esqueceu a senha?</Button>*/}
        </div>

        <Footer>
          <Button color="primary" onClick={this.handleSubmit}>
            { this.props.signInPage.complete.sending ? 'Entrando...' : 'Entrar' }
          </Button>
        </Footer>

        { this.props.signInPage.complete.touched && (<small style={{color: 'red'}}>{this.props.signInPage.complete.error}</small>) }
      </>
    );
  }

  renderForm () {
    if (!this.props.signInPage.data.username) {
      return this.renderStepOne();
    }

    return this.renderStepTwo();    
  }
}

SignInForm.propTypes = {
  displaySignUp: PropTypes.bool,
  notifySessionChanges: PropTypes.bool,
  username: PropTypes.string
}

export default connect((state) => {
  return {
    signInPage: state.signInPage
  };
})(withRouter(SignInForm));